import React from 'react';
import Article from "./article/view/Article";

const HomePage = () => {
    return (
        <div className="">
            <Article id="bienvenue-chez-l'oxy-vitale"/>
        </div>
    );
};

export default HomePage;
